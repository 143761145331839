import ES from './eventsprout';
require('url-polyfill');
function docReady(fn) {
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
        setTimeout(fn, 1);
    }
    else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}
docReady(function () {
    var es = new ES;
    es.createFrames();
    window.createEsFrame = function (url) {
        es.showFrame(url, new URL(location.href));
    };
    document.onkeydown = function (event) {
        var key = event.key || event.keyCode;
        if (key === 'Escape' || key === 'Esc' || key === 27) {
            es.hideAllFrames();
        }
    };
});
