import * as iframeEvents from './iframe-events';
import debounce from 'lodash/debounce';
var ES = /** @class */ (function () {
    function ES() {
        var _this = this;
        var _a;
        this.whiteListedParams = [
            'access_code',
            'kmi',
            'email',
            '_ga',
            'c_c',
            '_gl',
        ];
        this.APP_URL = new URL((_a = process.env.MIX_BASE_URL) !== null && _a !== void 0 ? _a : 'https://www.eventsprout.com');
        this.frames = {};
        this.loadedFrames = {};
        this.access_code = null;
        this.hideAll = document.createElement('style');
        this.hideAll.innerHTML = 'body > *:not(.es-iframe) { display: none !important; }';
        // Add Resize listeners for when height of view port changes on mobile :(
        window.addEventListener('resize', debounce(function () {
            if (_this.isMobile()) {
                for (var frameId in _this.frames) {
                    _this.frames[frameId].style.height = window.innerHeight + "px";
                }
            }
        }, 100));
    }
    ES.prototype.createFrames = function () {
        var _this = this;
        var tempLink = document.createElement('a');
        tempLink.href = this.APP_URL.toString();
        if (tempLink.hostname !== window.location.hostname) {
            var anchors = document.querySelectorAll('a:not([data-es-ignore])');
            anchors.forEach(function (anchor) { return _this.modifyAnchor(anchor); });
        }
    };
    ES.prototype.modifyAnchor = function (anchor) {
        var _this = this;
        var link = anchor.href;
        if (link) {
            var originalUrl = new URL(link);
            if (this.isEventSproutUrl(originalUrl)) {
                var eventSlug_1 = this._extractId(link);
                if (eventSlug_1) {
                    anchor.addEventListener("click", function (e) {
                        e.preventDefault();
                        _this.delayedModification(eventSlug_1, anchor);
                    });
                }
            }
        }
    };
    ES.prototype.delayedModification = function (eventSlug, anchor) {
        var _this = this;
        setTimeout(function () {
            var link = anchor.href;
            if (link) {
                var originalUrl = new URL(link);
                if (_this.loadedFrames[eventSlug] === undefined) {
                    _this.loadedFrames[eventSlug] = false;
                }
                _this.showFrame(eventSlug, originalUrl);
            }
        }, 1);
    };
    ES.prototype._createFrame = function (event_url, originalUrl) {
        var _this = this;
        var params = this._addWhiteListedParams(originalUrl.searchParams).toString();
        var iframe = document.createElement("iframe");
        iframe.src = process.env.MIX_BASE_URL.replace(/\/$/, "") + "/event/" + event_url + "/embed?" + params;
        iframe.id = "es-" + event_url;
        iframe.className = 'es-iframe';
        iframe.allowFullscreen = true;
        // @ts-ignore
        iframe.allowTransparency = true;
        iframe.style.position = 'fixed';
        iframe.style.top = '0px';
        iframe.style.left = '0px';
        iframe.style.margin = '0';
        iframe.style.padding = '0';
        iframe.style.border = 'none';
        iframe.style.height = '100vh';
        iframe.style.width = '100vw';
        iframe.style.zIndex = '2147483647';
        document.body.appendChild(iframe);
        this.frames[event_url] = iframe;
        iframe.addEventListener("load", function () {
            _this.loadedFrames[event_url] = true;
            if (_this.isMobile()) {
                iframe.style.height = window.innerHeight + "px";
                document.body.appendChild(_this.hideAll);
            }
        });
        window.addEventListener("message", function (event) {
            switch (event.data) {
                case iframeEvents.POPUPHIDE:
                    _this.hideFrame(iframe);
                    break;
            }
        }, false);
        return iframe;
    };
    ES.prototype.showFrame = function (event_url, originalUrl) {
        var _this = this;
        var iframe = this.frames[event_url];
        if (iframe) {
            iframe.style.display = 'block';
            if (this.isMobile()) {
                iframe.style.height = window.innerHeight + "px";
                document.body.appendChild(this.hideAll);
            }
        }
        else {
            iframe = this._createFrame(event_url, originalUrl);
        }
        document.body.style.cssText = 'overflow: hidden; height: auto';
        this._checkIFrameLoaded(event_url, function () {
            _this._sendMessageForSpecificTicket(iframe, originalUrl.searchParams.get("t"));
            _this._sendMessageForInnerGroup(iframe, originalUrl.searchParams.get("ig"));
            _this._sendMessageForSpecificGroup(iframe, originalUrl.searchParams.get("g"));
        });
    };
    ES.prototype.hideFrame = function (frame) {
        if (this.isMobile()) {
            document.body.removeChild(this.hideAll);
        }
        document.body.style.cssText = 'overflow: auto; overflow: initial';
        frame.style.display = 'none';
    };
    ES.prototype.hideAllFrames = function () {
        for (var frame in this.frames) {
            this.hideFrame(this.frames[frame]);
        }
    };
    ES.prototype.setToSpecificTicketIfAvailable = function (url) {
        try {
            var parsedUrl = new URL(url);
            this.selected_ticket = parsedUrl.searchParams.get("t");
        }
        catch (e) {
            this.selected_ticket = null;
        }
    };
    ES.prototype.setToSpecificGroupIfAvailable = function (url) {
        try {
            var parsedUrl = new URL(url);
            this.selected_group = parsedUrl.searchParams.get("g");
        }
        catch (e) {
            this.selected_group = null;
        }
    };
    ES.prototype.isMobile = function () {
        var check = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
                check = true;
            }
        })(navigator.userAgent || navigator.vendor);
        return check;
    };
    ES.prototype.isEventSproutUrl = function (url) {
        return this.APP_URL.hostname === url.hostname || this.APP_URL.hostname === "www." + url.hostname;
    };
    ES.prototype._extractId = function (url) {
        var hostname = process.env.MIX_BASE_DOMAIN;
        hostname = hostname.replace('www.', '');
        var regex = new RegExp(hostname + "\\/event\\/([^/?#]+)");
        var match = url.match(regex);
        if (match) {
            return match[1];
        }
        return null;
    };
    ES.prototype._sendMessageForSpecificTicket = function (iframe, ticket_id) {
        if (ticket_id !== null) {
            iframe.contentWindow.postMessage({
                type: 'SelectTicket',
                ticket_id: ticket_id,
            }, '*');
        }
    };
    ES.prototype._sendMessageForSpecificGroup = function (iframe, group_id) {
        if (group_id !== null) {
            iframe.contentWindow.postMessage({
                type: "SelectGroup",
                group_id: group_id,
            }, "*");
        }
    };
    ES.prototype._sendMessageForInnerGroup = function (iframe, group_id) {
        if (group_id !== null) {
            iframe.contentWindow.postMessage({
                type: 'SelectInnerGroup',
                group_id: group_id
            }, '*');
        }
    };
    ES.prototype._checkIFrameLoaded = function (iframeName, callback) {
        var _this = this;
        if (this.loadedFrames[iframeName]) {
            callback();
            return;
        }
        window.setTimeout(function () { return _this._checkIFrameLoaded(iframeName, callback); }, 100);
    };
    ES.prototype._addWhiteListedParams = function (params) {
        var current_page_url = new URL(location.href);
        this.whiteListedParams.forEach(function (whiteListedParam) {
            var paramValue = current_page_url.searchParams.get(whiteListedParam);
            if (paramValue !== null && paramValue !== '') {
                params.set(whiteListedParam, paramValue);
            }
        });
        return params;
    };
    return ES;
}());
export default ES;
